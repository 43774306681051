import React from 'react';
import { makeStyles } from '@material-ui/core';
import SartoriusLogo from './logo/Sartorius-Logo-RGB-Negativ.svg';

const useStyles = makeStyles({
  img: {
    width: 'auto',
    height: '60px',
  },
});
const LogoFull = () => {
  const classes = useStyles();

  return (
    <img className={classes.img} src={SartoriusLogo} alt="Sartorius Logo" />
  );
};

export default LogoFull;
