import React from 'react';
import {
    HomePageStarredEntities,
    HomePageRandomJoke,
    HomePageTopVisited,
    HomePageRecentlyVisited,
    CustomHomepageGrid,
    HeaderWorldClock,
    ClockConfig,
    FeaturedDocsCard 
  } from '@backstage/plugin-home';
import { Content, Page, Header } from '@backstage/core-components';
import { SearchContextProvider } from '@backstage/plugin-search-react';
import { HomePageSearchBar} from '@backstage/plugin-search';
import { MicrosoftCalendarCard } from '@backstage-community/plugin-microsoft-calendar';
import { HomePageToolkit } from './homePageComponents/Toolkit';
import { HomePageDescription } from './homePageComponents/Description';
import { AnnouncementsCard } from '@procore-oss/backstage-plugin-announcements';
import { ToolboxHomepageCard } from '@drodil/backstage-plugin-toolbox';
import { JiraUserIssuesViewCard } from '@axis-backstage/plugin-jira-dashboard';


  export const HomePage = () => {
  // This is the default configuration that is shown to the user
  // when first arriving to the homepage.
  const defaultConfig = [
    {
      component: 'HomePageSearchBar',
      x: 1,
      y: 0,
      width: 10,
      height: 2,
    },
    {
      component: 'HomePageStarredEntities',
      x: 0,
      y: 2,
      width: 3,
      height: 6,
    },
    {
      component: 'HomePageTopVisited',
      x: 0,
      y: 8,
      width: 3,
      height: 4,
    },
    {
      component: 'HomePageRecentlyVisited',
      x: 0,
      y: 12,
      width: 3,
      height: 4,
    },
    {
      component: 'FeaturedDocsCard',
      x: 3,
      y: 2,
      width: 3,
      height: 6,
    },
    {
      component: 'AnnouncementsCard',
      x: 6,
      y: 2,
      width: 3,
      height: 6,
    },
    {
      component: 'MicrosoftCalendarCard',
      x: 9,
      y: 2,
      width: 3,
      height: 6,
    },
    {
      component: 'JiraUserIssuesViewCard',
      x: 3,
      y: 8,
      width: 6,
      height: 8,
    }
  ];

  const clockConfigs: ClockConfig[] = [
    {
      label: 'New York',
      timeZone: 'America/New_York',
    },
    {
      label: 'UTC',
      timeZone: 'UTC',
    },
    {
      label: 'Göttingen',
      timeZone: 'Europe/Berlin',
    },
    {
      label: 'Bangalore',
      timeZone: 'IST',
    },
  ];

  const timeFormat: Intl.DateTimeFormatOptions = {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  };

  return (
        <SearchContextProvider>
            <Page themeId="home">
                <Header title="Home" subtitle="Developer Portal">
                  <HeaderWorldClock
                    clockConfigs={clockConfigs}
                    customTimeFormat={timeFormat}
                  />
                </Header>
                    <Content>
                        <CustomHomepageGrid config={defaultConfig}>
                          // Insert the allowed widgets inside the grid. User can add, organize and
                          // remove the widgets as they want.
                          <AnnouncementsCard max={3}/>
                          <FeaturedDocsCard
                            filter={{
                              'spec.type': 'documentation',
                              'metadata.name': 'backstage-onboarding',
                            }}
                            subLinkText="Link to docs"
                            linkDestination={'/docs/default/component/backstage-onboarding'}
                            responseLimit={5}
                          />
                          <HomePageDescription />
                          <HomePageRandomJoke />
                          <HomePageRecentlyVisited/>
                          <HomePageSearchBar />
                          <HomePageStarredEntities />
                          <HomePageToolkit />
                          <HomePageTopVisited />
                          <JiraUserIssuesViewCard
                            bottomLinkProps={{
                              link: 'https://jira.sartorius.com/issues',
                              title: 'Open in Jira',
                            }}
                          />
                          <MicrosoftCalendarCard />
                          <ToolboxHomepageCard />
                        </CustomHomepageGrid>
                    </Content>
            </Page>
        </SearchContextProvider>
  );
};