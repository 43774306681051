import { createCardExtension } from '@backstage/plugin-home-react';
import { homePlugin } from '@backstage/plugin-home';

export const HomePageDescription = homePlugin.provide(
    createCardExtension({
      description: 'The main goals of backstage',
      name: 'HomePageDescription',
      components: () => import('./Content'),
      layout: {
        height: {
          minRows: 6,
        },
      },
    }),
  );