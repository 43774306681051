import { microsoftAuthApiRef } from '@backstage/core-plugin-api';
import {
  ScmIntegrationsApi,
  scmIntegrationsApiRef,
  ScmAuth,
} from '@backstage/integration-react';
import {
  AnyApiFactory,
  configApiRef,
  createApiFactory,
//  discoveryApiRef,
  fetchApiRef,
  storageApiRef,
  identityApiRef,
//  errorApiRef
} from '@backstage/core-plugin-api';

import {
  MicrosoftCalendarApiClient,
  microsoftCalendarApiRef,
} from '@backstage-community/plugin-microsoft-calendar';
//import { UserSettingsStorage } from '@backstage/plugin-user-settings';

import {
  VisitsStorageApi,
  visitsApiRef,
} from '@backstage/plugin-home';

export const apis: AnyApiFactory[] = [
  createApiFactory({
    api: scmIntegrationsApiRef,
    deps: { configApi: configApiRef },
    factory: ({ configApi }) => ScmIntegrationsApi.fromConfig(configApi),
  }),
  createApiFactory({
    api: microsoftCalendarApiRef,
    deps: { authApi: microsoftAuthApiRef, fetchApi: fetchApiRef },
    factory: deps => new MicrosoftCalendarApiClient(deps),
  }),
/**
  createApiFactory({
    api: storageApiRef,
    deps: {
      discoveryApi: discoveryApiRef,
      errorApi: errorApiRef,
      fetchApi: fetchApiRef,
      identityApi: identityApiRef
    },
    factory: deps => UserSettingsStorage.create(deps),
  }),
*/
  createApiFactory({
    api: visitsApiRef,
    deps: {
      storageApi: storageApiRef,
      identityApi: identityApiRef,
    },
    factory: ({ storageApi, identityApi }) =>
      VisitsStorageApi.create({ storageApi, identityApi }),
  }),
  ScmAuth.createDefaultApiFactory(),
];
