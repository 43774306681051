import { createCardExtension } from '@backstage/plugin-home-react';
import { homePlugin } from '@backstage/plugin-home';

export const HomePageToolkit = homePlugin.provide(
    createCardExtension({
      description: 'A list of shortcuts to our development tools',
      name: 'HomePageToolkit',
      components: () => import('./Content'),
      layout: {
        height: {
          minRows: 6,
        },
      },
    }),
  );