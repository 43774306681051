import {
    DomainExplorerContent,
    GroupsExplorerContent,
    ToolExplorerContent,
    ExploreLayout,
  } from '@backstage-community/plugin-explore';
import React from 'react';
import { JFrogLibVerPageContent } from 'backstage-plugin-jfrog-artifactory-libs';
import {
  Direction
} from '@backstage/plugin-catalog-graph';

  export const ExplorePage = () => {
    return (
      <ExploreLayout
        title="Explore the Sartorius ecosystem"
        subtitle="Browse our ecosystem"
      >

        <ExploreLayout.Route path="organigram" title="Organigram" >
          <GroupsExplorerContent direction={'BT' as Direction} title="Organigram" />
        </ExploreLayout.Route>

        <ExploreLayout.Route path="domains" title="Domains">
          <DomainExplorerContent />
        </ExploreLayout.Route>

        <ExploreLayout.Route path="libver" title="Libraries">
          <JFrogLibVerPageContent />
        </ExploreLayout.Route>

        <ExploreLayout.Route path="tools" title="Tools">
          <ToolExplorerContent />
        </ExploreLayout.Route>

      </ExploreLayout>
    );
  };

  export const explorePage = <ExplorePage />;