import {
  createBaseThemeOptions,
  createUnifiedTheme,
  palettes,
} from '@backstage/theme';

const colors = {
  yellow: {
    main: '#ffed00',
    contrastText: '#000000',
    light: '#ffed00',
    dark: '#ffed00',
  },
  black: {
    main: '#000000',
    contrastText: '#ffffff',
    light: '#000000',
    dark: '#000000',
  },
  grey: {
    main: '#999999',
    contrastText: '#ffffff',
    light: '#c3c3c3',
    dark: '#333333',
  },
};

export const SartoDark = createUnifiedTheme({
...createBaseThemeOptions({
  palette: {
    ...palettes.dark,
  primary: colors.yellow,
  secondary: colors.yellow,
  navigation: {
    background: colors.black.main,
    indicator: colors.yellow.main,
    color: colors.grey.light,
    selectedColor: colors.black.contrastText,
  },
  },
}),
fontFamily: 'TT Norms Pro',
defaultPageTheme: 'home',
pageTheme: {
  home: {
    backgroundImage: `linear-gradient(${colors.black.main}, ${colors.black.main});`,
    fontColor: colors.black.contrastText,
    colors: [],
    shape: '',
  },
},
});